import Siema from 'siema';

export default class Slider {


    constructor(elem) {
        this.elements = {
            holder: elem,
        }

        this.elements.target = this.elements.holder.querySelector('.js-slider');
        this.elements.background = this.elements.holder.querySelector('.js-slider-background');
        this.elements.nextButton = this.elements.holder.querySelector('.js-slider-btn-next');
        this.elements.prevButton = this.elements.holder.querySelector('.js-slider-btn-prev');

        this.settings = {
            loop: true
        }

        this.mySiema;

        //this.onInit = this.onInit.bind(this);
        this.onResize = this.onResize.bind(this);

        if (this.elements.target) {
            const items = this.elements.target.children;

            this.mySiema = new Siema({
                selector: this.elements.target,
                startIndex: 0,
                perPage: {
                    600: items.length >= 2 ? 2 : items.length, // 2 items for viewport wider than 600px
                    1240: items.length >= 3 ? 3 : items.length, // 3 items for viewport wider than 1240px
                    1800: items.length >= 4 ? 4 : items.length // 4 items for viewport wider than 1800px
                },
                loop: this.settings.loop,
                onInit: () => this.onInit(),
                onChange: () => this.onChange()
            });

            this._setDOMReferences();
        }
    }

    _setDOMReferences() {
        if(this.elements.nextButton) {
            this.elements.nextButton.addEventListener('click', () => this.mySiema.next());
        }
        if(this.elements.prevButton) {
            this.elements.prevButton.addEventListener('click', () => this.mySiema.prev());
        }

        window.addEventListener("resize", this.onResize);
    }

    onResize() {
        const targetHeight = this.elements.target.offsetHeight;

        if(this.elements.background && targetHeight > 0) {
            this.elements.background.style.height = targetHeight + 'px';
        }
    }

    onInit() {
        this.elements.target.style.overflow = 'initial';

        // TODO: remove timeout
        const self = this;
        setTimeout(function(){
            const targetHeight = self.elements.target.offsetHeight;

            if(self.elements.background && targetHeight > 0) {
                self.elements.background.style.height = targetHeight + 'px';
            }
        }, 300);

        if (this.settings.loop == true) {return}
        this.elements.prevButton.disabled = true;
    }

    onChange() {
        if (this.settings.loop == true) {return}

        const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

        let perPage = 3

        if (vw > 1800) {perPage = 4} else
        if (vw > 1240) {perPage = 3} else
        if (vw > 600) {perPage = 2} else {perPage = 1}

        const index = this.mySiema.currentSlide;
        this.elements.prevButton.disabled = (0 === index);
        this.elements.nextButton.disabled = (
            index === this.mySiema.innerElements.length + 1 ||
            index + perPage >= this.mySiema.innerElements.length
        );
    }
}

export default class Header {

    constructor() {
        this.elements = {
            target: document.querySelector('.js-header'),
            trigger: document.querySelector('.js-header-trigger'),
            offcanvas: document.querySelector('.js-offcanvas'),
            offcanvasTrigger: document.querySelector('.js-toggle-offcanvas')
        }

        this.settings = {
            delta: 5,
            offcanvasState: false
        }

        this.lastScrollTop = 0;

        // Bind this to functions
        this.onToggleOffcanvas = this.onToggleOffcanvas.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onResize = this.onResize.bind(this);

        if(this.elements.target) {
            if(this.elements.trigger) {
                this.triggerTop = this.elements.trigger.offsetTop;
            }

            this.targetHeight = this.elements.target.offsetHeight;

            this._setDOMReferences();
        }
   }

    _setDOMReferences() {
        if(this.elements.offcanvas && this.elements.offcanvasTrigger) {
            this.elements.offcanvasTrigger.addEventListener("click", this.onToggleOffcanvas);
        }
        window.addEventListener("scroll", this.onScroll);
        window.addEventListener("resize", this.onResize);
    }

    onToggleOffcanvas() {
        this.settings.offcanvasState ? this.settings.offcanvasState = false : this.settings.offcanvasState = true;
        if(this.settings.offcanvasState) {
            this.elements.offcanvas.classList.add('is--visible');
        } else {
            this.elements.offcanvas.classList.remove('is--visible');
        }
    }

    onResize() {
        if(this.elements.trigger) {
            this.triggerTop = this.elements.trigger.offsetTop;
        }
    }

    onScroll() {
        const st = window.pageYOffset || document.documentElement.scrollTop;

        if(Math.abs(st - this.lastScrollTop) <= this.settings.delta) {
            return;
        }

        // Check transparant header
        if(this.triggerTop) {
            if(st > (this.triggerTop - this.targetHeight)) {
                this.elements.target.classList.remove('header--transparent');
            } else {
                this.elements.target.classList.add('header--transparent');
            }
        }

        if (st > this.lastScrollTop){
            // scroll down
            if(!this.settings.offcanvasState){
                this.elements.target.classList.add('header--up');
            }
        } else {
            // scroll up
            this.elements.target.classList.remove('header--up');
        }

        this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }
}

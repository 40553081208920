import {gsap} from "gsap";

export default class Carousel {

    constructor(elem) {

        this.elements = {
            target: elem,
        }

        this.elements.wrapper = this.elements.target.querySelector('.js-carousel-wrapper');
        this.elements.children = this.elements.target.querySelectorAll('.js-carousel-item');

        this.settings = {
            amount: this.elements.children.length,
            carouselHeight: 110, // set by setDefaults()
            carouselWidth: 0, // set by setDefaults()
            duration: 10 // set by setDefaults()
        }

        // Bind this to functions
        this.onMouseenter = this.onMouseenter.bind(this);
        this.onMouseleave = this.onMouseleave.bind(this);
        this.onResize = this.onResize.bind(this);
        this.setDefaults = this.setDefaults.bind(this);

        this.setDefaults();

        if(this.elements.target.dataset.reverse == "true") {
            this.runReverseAnimation();
        } else {
            this.runAnimation();
        }

        this._setDOMReferences();

        window.addEventListener('load', this.setDefaults);
    }

    setDefaults() {
        const self = this;

        this.settings.carouselWidth = 0;

        this.elements.children.forEach(function (child, i) {
            self.settings.carouselHeight = (child.offsetHeight > self.settings.carouselHeight) ? child.offsetHeight : self.settings.carouselHeight;
            self.settings.carouselWidth += child.offsetWidth;

            gsap.set(child, {
                x: i * child.offsetWidth
            });
        });

        this.elements.wrapper.style.height = this.settings.carouselHeight + 'px';
        //this.settings.carouselWidth = (this.settings.carouselWidth > this.elements.target.offsetWidth) ? this.settings.carouselWidth : this.elements.target.offsetWidth;
        this.elements.wrapper.style.width = self.settings.carouselWidth + 'px';

        this.settings.duration = this.settings.carouselWidth / 40;
    }

    _setDOMReferences() {
        this.elements.target.addEventListener("mouseenter", this.onMouseenter);
        this.elements.target.addEventListener("mouseleave", this.onMouseleave);
        window.addEventListener("resize", this.onResize);
    }

    onMouseenter() {
        // this.animation.pause();
    }

    onMouseleave() {
        // this.animation.play();
    }

    onResize() {
        this.setDefaults();
    }

    runAnimation() {
        const self = this;

        this.animation = gsap.to(this.elements.children, this.settings.duration, {
            x: "+=" + this.settings.carouselWidth,
            modifiers: {
                x: function(x) {
                    x = parseInt(x);
                    return x % self.settings.carouselWidth + 'px';
                }
            },
            ease: "none",
            repeat: -1,
        });
    }

    runReverseAnimation() {
        const self = this;

        this.animation = gsap.to(this.elements.children, this.settings.duration, {
            x: "-=" + this.settings.carouselWidth,
            modifiers: {
                x: function(x) {
                    x = parseInt(x);
                    return (x < 0) ? (x % self.settings.carouselWidth) + self.settings.carouselWidth + 'px' : x + 'px';
                }
            },
            ease: "none",
            repeat: -1,
        });
    }
}

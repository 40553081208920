export default class Tab {

    constructor(elem) {
        this.elements = {
            holder: elem,
        }

        this.elements.tabs = this.elements.holder.querySelectorAll('.js-tab');
        this.elements.accordeonTabs = this.elements.holder.querySelectorAll('.js-accordeon-tab');
        this.elements.tabContentElems = this.elements.holder.querySelectorAll('.js-tab-content');

        this.breakpoint = window.matchMedia('(max-width: 480px)');
        if (this.breakpoint.matches) {
            this.isPalm = true;
            this.collapseAll();
        } else {
            this.isPalm = false;
            this.setFirst();
        }

        this.onResize = this.onResize.bind(this);

        if(this.elements.tabs.length  && this.elements.tabContentElems.length) {
            this._setDOMReferences();
        }
    }

    _setDOMReferences() {
        for (let i = 0; i < this.elements.tabs.length; i++) {
            const id = this.elements.tabs[i].dataset.tabId;
            this.elements.tabs[i].addEventListener("click", () => this.activedTab(id, 'switch'));
        }
        for (let i = 0; i < this.elements.accordeonTabs.length; i++) {
            const id = this.elements.accordeonTabs[i].dataset.tabId;
            this.elements.accordeonTabs[i].addEventListener("click", () => this.activedTab(id, 'toggle'));
        }
        window.addEventListener("resize", this.onResize);
    }

    onResize() {
        if(this.breakpoint.matches && !this.isPalm) {
            this.collapseAll();
            this.isPalm = true;
        }
        if(!this.breakpoint.matches && this.isPalm) {
            this.setFirst();
            this.isPalm = false;
        }
    }

    collapseAll() {
        for (let i = 0; i < this.elements.tabs.length; i++) {
            this.elements.tabs[i].classList.remove('is--active');
        }
        for (let i = 0; i < this.elements.accordeonTabs.length; i++) {
            this.elements.accordeonTabs[i].classList.remove('is--active');
        }
        for (let i = 0; i < this.elements.tabContentElems.length; i++) {
            this.elements.tabContentElems[i].classList.remove('is--active');
        }
    }

    setFirst() {
        for (let i = 0; i < this.elements.tabs.length; i++) {
            if (i == 0) {
                this.elements.tabs[i].classList.add('is--active');
            } else {
                this.elements.tabs[i].classList.remove('is--active');
            }
        }
        for (let i = 0; i < this.elements.accordeonTabs.length; i++) {
            if (i == 0) {
                this.elements.accordeonTabs[i].classList.add('is--active');
            } else {
                this.elements.accordeonTabs[i].classList.remove('is--active');
            }
        }
        for (let i = 0; i < this.elements.tabContentElems.length; i++) {
            if (i == 0) {
                this.elements.tabContentElems[i].classList.add('is--active');
            } else {
                this.elements.tabContentElems[i].classList.remove('is--active');
            }
        }
    }

    activedTab(id, type) {
        for (let i = 0; i < this.elements.tabs.length; i++) {
            const tab = this.elements.tabs[i];

            if(type == 'switch') {
                if(tab.dataset.tabId == id) {
                    tab.classList.add('is--active');
                } else {
                    tab.classList.remove('is--active');
                }
            }

            if(type == 'toggle') {
                if(tab.dataset.tabId == id) {
                    if(tab.classList.contains('is--active')) {
                        tab.classList.remove('is--active');
                    } else {
                        tab.classList.add('is--active');
                    }
                }
            }
        }
        for (let i = 0; i < this.elements.accordeonTabs.length; i++) {
            const accordeon = this.elements.accordeonTabs[i];

            if(type == 'switch') {
                if(accordeon.dataset.tabId == id) {
                    accordeon.classList.add('is--active');
                } else {
                    accordeon.classList.remove('is--active');
                }
            }

            if(type == 'toggle') {
                if(accordeon.dataset.tabId == id) {
                    if(accordeon.classList.contains('is--active')) {
                        accordeon.classList.remove('is--active');
                    } else {
                        accordeon.classList.add('is--active');
                    }
                }
            }
        }
        for (let i = 0; i < this.elements.tabContentElems.length; i++) {
            const content = this.elements.tabContentElems[i];

            if(type == 'switch') {
                if(content.dataset.tabId == id) {
                    content.classList.add('is--active');
                } else {
                    content.classList.remove('is--active');
                }
            }

            if(type == 'toggle') {
                if(content.dataset.tabId == id) {
                    if(content.classList.contains('is--active')) {
                        content.classList.remove('is--active');
                    } else {
                        content.classList.add('is--active');
                    }
                }
            }
        }
    }
}

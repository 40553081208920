// Custom modules
import Header from './modules/Header';
import Carousel from './modules/Carousel';
import Slider from './modules/Slider';
import Tab from './modules/Tab';

/**
 *  App class
 *
 *  The App class functions is the main back-bone of the javascript used in this
 *  project.
 *
 */

class App {
    /**
     *  Constructor
     */
    constructor() {
        document.addEventListener('DOMContentLoaded', function () {
            this.onDocumentReady();
        }.bind(this));
    }

    onDocumentReady() {
        new Header();
    }

    handleCarousel(elem) {
        new Carousel(elem);
    }

    handleSlider(elem) {
        new Slider(elem);
    }

    handleTab(elem) {
        new Tab(elem);
    }

}

let target = document.getElementById('PartnerEventForm_Agree_Holder');
if (target) {
    target.classList.add('sign');
}

window.app = new App();
